import { default as _91_46_46_46slug_93YACQ5JLdqPMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue?macro=true";
import { default as checkoutI1qMJ5TFGBMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue?macro=true";
import { default as cookie_45deklarationE94gGuCrZdMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue?macro=true";
import { default as glemt_45kodeordShHHExCPAyMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue?macro=true";
import { default as logindlHgU3JpemEMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue?macro=true";
import { default as _91slug_9350mNviX8YoMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue?macro=true";
import { default as indexh8wyeAw5dIMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue?macro=true";
import { default as nyt_45kodeordPjVHULp9DyMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue?macro=true";
import { default as opretSMVZEnSLYzMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue?macro=true";
import { default as prereceiptCQUE9hw3hwMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue?macro=true";
import { default as reklamationz4kiHod304Meta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue?macro=true";
import { default as tak_45for_45din_45bestillingv6rfh4IV1YMeta } from "/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93YACQ5JLdqPMeta || {},
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue")
  },
  {
    name: "cookie-deklaration",
    path: "/cookie-deklaration",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue")
  },
  {
    name: "glemt-kodeord",
    path: "/glemt-kodeord",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue")
  },
  {
    name: "logind",
    path: "/logind",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue")
  },
  {
    name: "mitaller-slug",
    path: "/mitaller/:slug()",
    meta: _91slug_9350mNviX8YoMeta || {},
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue")
  },
  {
    name: "mitaller",
    path: "/mitaller",
    meta: indexh8wyeAw5dIMeta || {},
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue")
  },
  {
    name: "nyt-kodeord",
    path: "/nyt-kodeord",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue")
  },
  {
    name: "opret",
    path: "/opret",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue")
  },
  {
    name: "prereceipt",
    path: "/prereceipt",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue")
  },
  {
    name: "reklamation",
    path: "/reklamation",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue")
  },
  {
    name: "tak-for-din-bestilling",
    path: "/tak-for-din-bestilling",
    component: () => import("/codebuild/output/src2324144909/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue")
  }
]