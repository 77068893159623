<template>
  <Html lang="da">
    <Head>
      <Meta charset="utf-8" />
      <Link
        rel="apple-touch-icon"
        href="/apple-touch-icon.png"
        sizes="180x180"
      />
      <Link
        rel="icon"
        href="/favicon.ico"
        type="image/x-icon"
      />
      <Link
        rel="icon"
        href="/favicon-16x16.png"
        sizes="16x16"
        type="image/png"
      />
      <Link
        rel="icon"
        href="/favicon-32x32.png"
        sizes="32x32"
        type="image/png"
      />
      <Link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color="#acb9c0"
      />
    </Head>
  </Html>
</template>
