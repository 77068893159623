<template>
  <div id="app-root">
    <SiteHtml />
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#3C5C70 0%, #00C2A2 100%)" />
    <LazySiteHeader />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <LazySiteFooter />
    <Toaster
      position="top-center"
      rich-colors
      close-button
    />
  </div>
</template>

<script setup lang="ts">
import { Toaster } from 'vue-sonner';

// Inject globalApp. Concept, cookiebot, tcf etc.
useAllerGlobalApp();

// Get native state telling us if this is a native page or not.
useState('native', () => true);

// Inject additional scripts.
useConsentRequiredScripts();
</script>
