/**
 * Fix instagram "call pop up" issue with cookiebot by altering styling.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const requestheaders = useRequestHeaders();
  if (requestheaders['user-agent']?.match(/FBAN|FBAV|Instagram/i)) {
    useHead({
      style: [
        {
          id: 'cbotinstafix',
          innerHTML: '#CybotCookiebotDialog{max-height: calc(100% - 200px) !important; top: 5px !important; transform: translate(-50%, 0) !important;}',
        },
      ],
    });
  }
});
