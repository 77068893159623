import { BrandTransformer, FooterTransformer, SettingsTransformer } from '@aller/nuxt-allerservice-sdk/transformers';

export default defineNuxtPlugin(async (nuxtApp) => {
  // We need to get the start data to be able to display the siteHeader/Footer/strings.
  const start = await useOptimizely().content().byPath('start', ['*']);
  // Inject globals into nuxt state for usage.
  // Settings holds the strings used in various forms etc.
  useState('settings', () => SettingsTransformer(start.value));
  // Footer holds the payment-options-images (used in checkout).
  useState('footer', () => FooterTransformer(start.value));
  // Inject start for easy access.
  useState('start', () => start);

  // If we don't have the allerserviceBrandId we presume we want all brand data in the state.
  // To avoid additional response time on server, we only do this clientside.
  // This will force all cart displays that use the brands state to be client only, as the state can not be synced from SSR.
  if (import.meta.client) {
    // For allerservice we want all brands in the state.
    const brandsState = useState('brands');

    const brands = await useOptimizely().content().childrenById(start.value.brandListingPage.guidValue as string, ['*'], false, [
    'title', 'additionalImages', 'coverImages', 'url', 'logo', 'heroMedia', 'publicationCode', 'tagline', 'name', 'contentLink', 'primaryColor', 'primaryTextColor', 'secondaryColor', 'secondaryTextColor',
  ]);
    brandsState.value = brands.value.map((brand) => BrandTransformer(brand));
  }

  // Inject a localReferrer globally. Will be modified by middleware (Used in dataLayer).
  useState<string | undefined>('localReferrer', () => undefined);
});
