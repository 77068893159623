<template>
  <div id="error">
    <SiteHtml />
    <LazySiteHeader />
    <main class="relative z-10">
      <WidthWrapper class="pb-15 pt-85 md:pb-25 md:pt-90">
        <PageTitle
          small
          :title="error?.statusCode === 404 ? 'Beklager, men vi kunne ikke finde den side, du er på udkig efter.' : 'Øv noget gik galt.'"
          class="mx-auto mb-20 max-w-610 text-center"
        />
        <p class="mx-auto max-w-610 px-15 pb-45 text-center text-20 md:px-35 lg:px-0 lg:text-18">
          Beklager, men vi kunne ikke finde den side, du er på udkig efter.<br>
          Tjek venligst, at du har angivet webadressen korrekt.<br>
          Prøv vores <NuxtLink
            to="/"
            class="underline"
          >
            forside
          </NuxtLink> eller fortæl os om fejlen via vores kundeservice.<br>
          Gå videre ved at klikke dig videre i menuen.
        </p>
      </WidthWrapper>
    </main>
    <LazySiteFooter />
  </div>
</template>

<script lang="ts" setup>
defineProps({
  error: Object,
});

// Inject globalApp. cookiebot, tcf etc.
useAllerGlobalApp();

// Inject additional scripts.
useConsentRequiredScripts();
</script>
